import { defaultPitch } from './pitch'
import { canadianFootballEventTypes } from './events'
import { outcomeTypes } from '../../outcomes/data_types'
import { pitchSetupTypes } from '../../pitches/data_types'
import { FlightEventTypeGroup } from '../../events/flight/data_types'
import { FormattedEventData } from '../../events/types'
import { gameEventSubTypes } from '../../events/game/subType/data_types'
import { gameEventTypes } from '../../events/game/data_types'
import dataTypes from './data_types_config.json'
import {
  getMetricTypeGroup,
  getMetricTypesConfigFromJson,
  metricTypesConfig
} from '../../metrics/data_types'

export const canadianFootballProps = {
  eventTypes: canadianFootballEventTypes,
  playerSummaryMetricTypes: getMetricTypeGroup(
    getMetricTypesConfigFromJson(
      dataTypes.playerSummary.metrics,
      metricTypesConfig
    )
  ),
  pitch: {
    default: defaultPitch,
    setupTypes: pitchSetupTypes,
    minPadding2D: 70,
    minPadding3D: 50,
    cameraYScale: 0.5,
    textureCanvasScale: 13,
    tenYardMarkings: 11,
    enableBench: true
  },
  features: {
    enablePromoteAllDevicesOnPitch: true,
    targetEvents: ['pass'],
    positionNumbers: false,
    maxPlayers: 46,
    defaultUnitSystem: 'Canadian',
    keyboardShortcuts: {
      shortcuts: {
        c: {
          condition: (
            formattedEvent: FormattedEventData,
            flightTypes: FlightEventTypeGroup
          ) => {
            return (
              formattedEvent &&
              (formattedEvent.type.selected?.value ===
                flightTypes.items.pass?.value ||
                formattedEvent.type.selected?.value ===
                  gameEventTypes.items.manualPass?.value ||
                formattedEvent.type.selected?.value ===
                  gameEventTypes.items.touchDown?.value)
            )
          },
          outcome: outcomeTypes.items.complete?.value,
          subType: gameEventSubTypes.items.manualPassComplete?.value
        },
        x: {
          condition: (
            formattedEvent: FormattedEventData,
            flightTypes: FlightEventTypeGroup
          ) => {
            return (
              formattedEvent &&
              (formattedEvent.type.selected?.value ===
                flightTypes.items.pass?.value ||
                formattedEvent.type.selected?.value ===
                  gameEventTypes.items.manualPass?.value)
            )
          },
          outcome: outcomeTypes.items.incomplete?.value,
          subType: gameEventSubTypes.items.manualPassIncomplete?.value
        },
        // d: {
        //   condition: (
        //     formattedEvent: FormattedEventData,
        //     flightTypes: FlightEventTypeGroup
        //   ) => {
        //     return (
        //       formattedEvent &&
        //       formattedEvent.type.selected?.value ===
        //         flightTypes.items.pass?.value
        //     )
        //   },
        //   outcome: outcomeTypes.items.touchdown?.value
        // },
        i: {
          condition: (
            formattedEvent: FormattedEventData,
            flightTypes: FlightEventTypeGroup
          ) => {
            return (
              formattedEvent &&
              (formattedEvent.type.selected?.value ===
                flightTypes.items.pass?.value ||
                formattedEvent.type.selected?.value ===
                  gameEventTypes.items.manualPass?.value)
            )
          },
          outcome: outcomeTypes.items.interception?.value,
          subType: gameEventSubTypes.items.manualPassIntercepted?.value
        }
      },
      codingShortcuts: {
        p: {
          type: 'createGameEvent',
          payload:
            canadianFootballEventTypes.items.game.props.types.items.manualPass
        },
        s: {
          type: 'createGameEvent',
          payload:
            canadianFootballEventTypes.items.game.props.types.items.handOff
        },
        f: {
          type: 'createGameEvent',
          payload:
            canadianFootballEventTypes.items.game.props.types.items.tackle
        },
        n: {
          type: 'createGameEvent',
          payload:
            canadianFootballEventTypes.items.game.props.types.items.penalty
        },
        w: {
          type: 'createGameEvent',
          payload:
            canadianFootballEventTypes.items.game.props.types.items.huddleBreak
        },
        e: {
          type: 'createGameEvent',
          payload:
            canadianFootballEventTypes.items.game.props.types.items.lineSet
        },
        d: {
          type: 'createGameEvent',
          payload:
            canadianFootballEventTypes.items.game.props.types.items.qbRush
        },
        g: {
          type: 'createGameEvent',
          payload:
            canadianFootballEventTypes.items.game.props.types.items.qbSack
        }
      }
    },
    recentEventTableMetrics: [
      'inPitchDistance',
      'inPitchHangTime',
      'speed',
      'territorialGain'
    ],
    broadcastIntegrationAvailable: true,
    noOfPlayersInStartingLineUp: null,
    scoreboard: false,
    homeSideCheck: true,
    bibs: {}
  } as const
}
