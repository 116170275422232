import React, { useEffect, useState, useCallback, useMemo } from 'react'
import useKeyboardShortcut from 'use-keyboard-shortcut'
import { ConfirmationRequest } from '../../../components/ConfirmationRequest/ConfirmationRequest'
import { TeamLogoCell } from '../../../components/Table/CustomCells/TeamLogoCell/TeamLogoCell'
import useTimer from '../../../utils/hooks/useTimer'
import { Strack } from '../../../components/Strack/Strack.types'
import { useEvents } from '../../../metrics_server/events/hooks'
import { useAppDispatch } from '../../../store/hooks'
import {
  useAutoSleep,
  useSelectedFormattedSession,
  useSessionTeamSides
} from '../../../metrics_server/sessions/hooks'
import {
  switchPossession,
  switchTeamSides
} from '../../../metrics_server/broadcasting/actions'
import {
  demoteAllOffPitch,
  promoteAll,
  promoteAllOnPitch,
  resetSeatSwapping,
  serviceAction
} from '../../../metrics_server/hardware/actions'
import { toggleModal } from '../../../ui/modal/actions'
import {
  clearPenaltyCountdown,
  createGameEvent,
  createTimeEvent,
  deleteTimeEvent,
  endTimeEvent,
  startPenaltyCountdown,
  updateTimeEvent
} from '../../../metrics_server/events/actions'
import { useTimeEvents } from '../../../metrics_server/events/time/hooks'
import { TableRow } from '../../../components/Table/Table.types'
import {
  useBallInPlay,
  useFormattedHardware,
  useHardware
} from '../../../metrics_server/hardware/hooks'
import {
  isSportType,
  sportTypes
} from '../../../metrics_server/sports/data_types'
import { useEventsFilters } from '../../../metrics_server/events/filter'
import { toggleIgnoreTagForSleepAndWakeAll } from '../../../metrics_server/hardware/actions'
import styles from './Coding.module.scss'
import { Button } from '../../../components/Button/Button'
import { sportableColors } from '../../../const'
import { CardList } from '../../../components/CardList/CardList'
import { Toggle } from '../../../components/Toggle/Toggle'
import { sleepAllButOnField } from '../../../metrics_server/hardware/api'
import {
  Table,
  getItemIdFromRowId,
  getRowId
} from '../../../components/Table/Table'
import BasicSelect from '../../../components/Material/Select'
import { PeriodControls } from './HalfControls/HalfControls'
import { setSelectedDevice } from '../../../metrics_server/hardware/functions'
import { isDeviceType } from '../../../metrics_server/hardware/data_types'
import { setInfo } from '../../../ui/info/actions'
import { isRugbyUnion } from '../../../metrics_server/sports/functions'

export interface RugbyProps {
  strack: Strack
  activeTab
  active: boolean

  codingTableData: TableRow[]
  togglePossessionEvents
  toggleStoppageEvents
  visibleCodingTableEvents
}

export const Coding = (props: RugbyProps) => {
  const {
    strack,
    active,
    codingTableData,
    togglePossessionEvents,
    toggleStoppageEvents,
    visibleCodingTableEvents
  } = props
  // Redux //
  const events = useEvents()
  const hardware = useHardware()
  const dispatch = useAppDispatch()
  // ===== //

  // Events //
  const { inPlay, playStopped, timeEventTypes } = useTimeEvents()
  // ======= //

  // Session //
  // const sessionConfig = useSession()
  // const { session, live, gameEventTypes, sport, isKeyboardShortcutEnabled } =
  //   sessionConfig
  const formattedSession = useSelectedFormattedSession()
  const {
    id,
    live,
    sport,
    gameEventTypes,
    isKeyboardShortcutEnabled,
    playersSessions,
    teams
  } = formattedSession
  const { sessionTeamSides, possession } = useSessionTeamSides(id)
  const { autoSleepEnabled } = useAutoSleep()

  // ======= //

  const { ballInPlay } = useBallInPlay()

  // Event Filter //
  const timeEvents = useEventsFilters({}, ['time'])

  // Table Highlight
  const [highlightedEvent, setHighlightedEvent] = useState(null)
  const [highlightedRow, setHighlightedRow] = useState(null)
  const [devicesHighlightedRow, setDevicesHighlightedRow] = useState(null)
  const [buttonStates, setButtonStates] = useState('')
  const [selectedStoppageOption, setSelectedStoppageOption] = useState('SNAP')
  const [tableHeaders, setTableHeaders] = useState([])
  const [table, setTable] = useState<string>('coding')

  useEffect(() => {
    setTableHeaders(getRugbyUnionTableHeaders())
  }, [events.columns])

  useEffect(() => {
    if (hardware && hardware.selectedDevice) {
      setDevicesHighlightedRow(`${hardware.selectedDevice}-controlsTable`)
    }
  }, [hardware])

  const getRugbyUnionTableHeaders = () => {
    const headers = [
      { name: 'Time', key: 'startTime', width: 30, type: 'date' },
      { name: 'Type', key: 'typeName', width: 50 },
      {
        name: 'Team',
        key: 'teamLogo',
        width: 9,
        type: 'component',
        CustomComponent: TeamLogoCell
      },
      {
        name: '',
        key: 'deleteTimeEvent',
        type: 'icon',
        input: {
          callback: (item) => {
            openDeleteEventConfirmationModal(item)
          }
        }
      }
    ]

    return headers
  }

  // Stopwatch
  const {
    timer,
    isActive,
    isPaused,
    timerStart,
    timerPause,
    timerResume,
    timerReset
  } = useTimer(0)

  const { keyboardShortcuts } = sport.props.features

  const [controlHeld, setControlHeld] = useState(false)

  useEffect(() => {
    document.addEventListener('keydown', (event) => {
      if (event.key === 'Control') {
        setControlHeld(true)
      }
    })

    document.addEventListener('keyup', (event) => {
      if (event.key === 'Control') {
        setControlHeld(false)
      }
    })
  }, [])

  Object.keys(keyboardShortcuts?.codingShortcuts || {}).forEach((key) => {
    const { type, payload } = keyboardShortcuts.codingShortcuts[key]
    const handleShortcut = () => {
      if (
        type === 'createGameEvent' &&
        active &&
        inPlay &&
        live &&
        isKeyboardShortcutEnabled &&
        !controlHeld
      ) {
        dispatch(
          createGameEvent(formattedSession.id, {
            type: payload.value
          })
        )
      }
    }
    useKeyboardShortcut(
      [key],
      (event) => {
        handleShortcut()
      },
      {
        overrideSystem: isKeyboardShortcutEnabled && !controlHeld
      }
    )
  })

  // Start & Stop - Space Bar
  const handleSpaceBarPress = (e) => {
    if (!events.stoppageEventLoading && isKeyboardShortcutEnabled) {
      if (active && inPlay && live) {
        if (playStopped && selectedStoppageOption) {
          endStoppage(selectedStoppageOption)
        } else if (playStopped) {
          endStoppage()
        } else {
          startStoppage()
        }
      }
    }
  }

  useKeyboardShortcut([' '], (e) => handleSpaceBarPress(e), {
    overrideSystem: isKeyboardShortcutEnabled,
    repeatOnHold: false
  })

  const handleNPress = () => {
    if (
      active &&
      inPlay &&
      live &&
      !isSportType.canadianFootball(sport) &&
      !isSportType.americanFootball(sport) &&
      !isSportType.australianRules(sport) &&
      isKeyboardShortcutEnabled
    ) {
      handlePenatlyButtonClick()
    }
  }

  useKeyboardShortcut(['n'], () => handleNPress(), {
    overrideSystem: isKeyboardShortcutEnabled
  })

  const handleSwitchPossessionShortcut = (teamSide) => {
    if (teamSide === possession?.id) {
      return undefined
    } else {
      return dispatch(switchPossession({ id: `${teamSide}` }))
    }
  }

  // Switch possession to Right side
  const switchPossessionLeft = () => {
    if (strack && isKeyboardShortcutEnabled) {
      if (active) {
        if (live && !strack.pitchFlipped) {
          handleSwitchPossessionShortcut(sessionTeamSides[0].id)
        } else {
          handleSwitchPossessionShortcut(sessionTeamSides[1].id)
        }
      }
    }
  }

  useKeyboardShortcut(['ArrowLeft'], () => switchPossessionLeft(), {
    overrideSystem: isKeyboardShortcutEnabled
  })

  // Switch possession to Right side- /
  const switchPossessionRight = () => {
    if (strack && isKeyboardShortcutEnabled) {
      if (active) {
        if (live && !strack.pitchFlipped) {
          handleSwitchPossessionShortcut(sessionTeamSides[1].id)
        } else {
          handleSwitchPossessionShortcut(sessionTeamSides[0].id)
        }
      }
    }
  }

  useKeyboardShortcut(['ArrowRight'], () => switchPossessionRight(), {
    overrideSystem: isKeyboardShortcutEnabled
  })

  /* Stoppages */

  const openDeleteEventConfirmationModal = useCallback(
    (event) => {
      dispatch(
        toggleModal({
          active: true,
          type: 'confirm',
          handleProceed: () => {
            if (!event.start) {
              dispatch(
                updateTimeEvent(event.eventId, {
                  timeEnd: 0
                })
              )
              timerResume()
            } else {
              // if it is during 1st half or second half
              dispatch(deleteTimeEvent(event.eventId))
              timerReset()
            }
            dispatch(toggleModal({}))
          },
          ChildComponent: ConfirmationRequest,
          message: 'Are you sure?',
          className: 'modalSmall',
          handleClose: () => {
            dispatch(toggleModal({}))
          }
        })
      )
    },
    [events]
  )

  const startStoppage = () => {
    dispatch(
      createTimeEvent({
        sessionId: id,
        type: timeEventTypes.items.stoppage.value
      })
    )
  }

  const endStoppage = useCallback(
    (gameEvent?) => {
      let stoppageEvent
      timeEvents.filteredEvents.forEach((timeEvent) => {
        if (
          timeEvent.type === timeEventTypes.items.stoppage.value &&
          !timeEvent.timeEnd
        )
          stoppageEvent = timeEvent
      })
      dispatch(endTimeEvent(stoppageEvent.id, { ...stoppageEvent }, gameEvent))
      setSelectedStoppageOption('SNAP')
    },
    [timeEvents.filteredEvents]
  )

  const handleSwitchPossession = () => {
    if (possession?.id === sessionTeamSides[0].id) {
      return dispatch(switchPossession({ id: `${sessionTeamSides[1].id}` }))
    }
    dispatch(switchPossession({ id: `${sessionTeamSides[0].id}` }))
  }

  // Penalty button

  const handlePenatlyButtonClick = useCallback(() => {
    if (events.penaltyCountdownActive) {
      // Stop penalfty
      dispatch(clearPenaltyCountdown())
    } else {
      // Start penalty
      dispatch(startPenaltyCountdown())
    }
  }, [events.penaltyCountdownActive])

  // Swap sides

  const switchTeamSidesConfirmationModal = () => {
    dispatch(
      toggleModal({
        active: true,
        type: 'confirm',
        handleProceed: () => {
          dispatch(switchTeamSides())
          dispatch(toggleModal({}))
        },
        ChildComponent: ConfirmationRequest,
        message: 'Are you sure?',
        className: 'modalSmall',
        handleClose: () => {
          dispatch(toggleModal({}))
        }
      })
    )
  }

  const handleCreateGameEvent = (type) => {
    setButtonStates(type)
    setTimeout(() => {
      setButtonStates('')
    }, 500)

    dispatch(
      createGameEvent(id, {
        type: type
      })
    )
  }

  // Control table

  const controlTableHeaders = useMemo(() => {
    // TODO: type safe table component
    const headers: any = [
      {
        name: 'Ignore',
        key: 'ignore',
        width: 9,
        input: {
          type: 'checkbox',
          onChange: (item, bool) => {
            dispatch(toggleIgnoreTagForSleepAndWakeAll(item.id))
          }
        }
      },
      { name: 'Serial', key: 'serial' },
      { name: 'Battery', key: 'battery' },
      {
        name: 'Team',
        key: 'team',
        type: 'component',
        CustomComponent: TeamLogoCell
      },
      { name: 'Name', key: 'name' },
      { name: 'Number', key: 'number' }

      // TODO: this needs to be set based on MS configuration
      // {
      //   name: '',
      //   key: 'unseatButton',
      //   input: {
      //     type: 'button',
      //     callback: (item, value) => {
      //       dispatch(
      //         setError({
      //           message: `${value}: ${item.serial} - Are you sure?`,
      //           type: 'warning',
      //           proceed: () => {
      //             dispatch(serviceAction([item.id], value, true))
      //           }
      //         })
      //       )
      //       setHighlightedRow(null)
      //     }
      //   }
      // },
      // {
      //   name: '',
      //   key: 'greyListButton',
      //   input: {
      //     type: 'button',
      //     callback: (item, value) => {
      //       dispatch(serviceAction([item.id], value, true))
      //       setHighlightedRow(null)
      //     }
      //   }
      // }
    ]

    if (hardware.configuration?.lowPower > 0) {
      headers.push({
        name: '',
        key: 'promote',
        input: {
          type: 'button',
          callback: (item, value) => {
            dispatch(serviceAction([item.id], value, true))
            setHighlightedRow(null)
          }
        }
      })

      headers.push({
        name: '',
        key: 'demote',
        input: {
          type: 'button',
          callback: (item, value) => {
            dispatch(serviceAction([item.id], value, true))
            setHighlightedRow(null)
          }
        }
      })
    }

    return headers
  }, [hardware.configuration])

  const formattedHardware = useFormattedHardware()

  const getRowData = useCallback(() => {
    const devices = [
      ...formattedHardware.types.ball.devices.list,
      ...formattedHardware.types.playerTag.devices.list
    ]

    return devices.map((device) => {
      // Find player session corresponding to device
      const playerSession = playersSessions.byHardwareId.list.find(
        (session) => session.hardwareId === device.id
      )

      // Find team corresponding to player session
      const team = teams.list.find(
        (team) => team.id === (playerSession ? playerSession.teamId : '')
      )
      // Determine if the device belongs to teamA or teamB
      const teamA = team ? teams.list[0].id === team.id : false
      const teamB = team ? teams.list[1].id === team.id : false

      const row = {} as TableRow
      row.id = device.id
      row.serial = device.serial
      row.battery = device.battery.display
      row.team = team ? team : ''
      row.name = playerSession ? playerSession.player?.fullName : ''
      row.number = playerSession ? playerSession.player?.number : ''
      row.highlightColor = device.status.colour
      row.ignore = hardware.tagIdsIgnoredForSleepAndWakeAll.includes(device.id)
      row.teamA = teamA
      row.teamB = teamB
      row.ball = isDeviceType.ball(device.type)
      row.lowPower = device.status.value === 'sleep'
      row.highPower = device.status.value === 'online'

      row.__buttons = {
        promote: {
          text: 'Promote',
          value: 'power/promote'
        },
        demote: {
          text: 'Demote',
          value: 'power/demote'
        }
      }
      return row
    })
  }, [
    formattedHardware,
    hardware.tagIdsIgnoredForSleepAndWakeAll,
    autoSleepEnabled
  ])

  const tableData = useMemo(() => {
    return getRowData()
  }, [getRowData])

  return (
    <React.Fragment>
      {live && (
        <div className={styles.matchTimeContainer}>
          <CardList
            col={12}
            items={[{}]}
            scrollerId={`scroller-${1}`}
            className='maxHeight'
          >
            <div className={styles.gameTimeControls}>
              <div>
                <PeriodControls />
              </div>
              {/* <Stopwatch
                  timer={timer}
                  isActive={isActive}
                  isPaused={isPaused}
                  stopwatchStart={timerStart}
                  stopwatchPause={timerPause}
                  stopwatchResume={timerResume}
                  stopwatchReset={timerReset}
                /> */}

              {inPlay && (
                <div className={styles.stopwatchAndStoppageControlsContainer}>
                  {gameEventTypes.items.huddleBreak && (
                    <div>
                      <Button
                        style={{
                          backgroundColor:
                            buttonStates ===
                            gameEventTypes.items.huddleBreak.value
                              ? sportableColors.colors.colorSuccess
                              : sportableColors.colors.sportableBlue
                        }}
                        className='btn--ghost--primary btn--small'
                        handleClick={() =>
                          handleCreateGameEvent(
                            gameEventTypes.items.huddleBreak.value
                          )
                        }
                      >
                        {gameEventTypes.items.huddleBreak.name} (w)
                      </Button>
                    </div>
                  )}
                  {gameEventTypes.items.lineSet && (
                    <div>
                      <Button
                        style={{
                          backgroundColor:
                            buttonStates === gameEventTypes.items.lineSet.value
                              ? sportableColors.colors.colorSuccess
                              : sportableColors.colors.sportableBlue
                        }}
                        className='btn--ghost--primary btn--small'
                        handleClick={() =>
                          handleCreateGameEvent(
                            gameEventTypes.items.lineSet.value
                          )
                        }
                      >
                        {gameEventTypes.items.lineSet.name} (e)
                      </Button>
                    </div>
                  )}
                  {gameEventTypes.items.manualPass &&
                    inPlay &&
                    !playStopped && (
                      <Button
                        style={{
                          backgroundColor:
                            buttonStates ===
                            gameEventTypes.items.manualPass.value
                              ? sportableColors.colors.colorSuccess
                              : sportableColors.colors.sportableBlue
                        }}
                        className='btn--ghost--primary btn--small'
                        handleClick={() =>
                          handleCreateGameEvent(
                            gameEventTypes.items.manualPass.value
                          )
                        }
                      >
                        {gameEventTypes.items.manualPass.name} (p)
                      </Button>
                    )}

                  <div className={styles.penaltyStoppageButtons}>
                    {isSportType.canadianFootball(sport) ||
                    isSportType.americanFootball(sport) ? (
                      <div className={styles.startStopButton}>
                        {playStopped ? (
                          <div className={styles.sanpButtonContainer}>
                            <Button
                              handleClick={() => {
                                if (!events.stoppageEventLoading) {
                                  endStoppage(selectedStoppageOption)
                                }
                              }}
                              style={{
                                backgroundColor:
                                  timeEventTypes.items.stoppage.props.features
                                    .buttons.stop.colour,

                                borderRadius: '5px 0px 0px 5px',
                                height: '100%',
                                width: '60%'
                              }}
                            >
                              {selectedStoppageOption}
                            </Button>
                            <BasicSelect
                              borderRadius={'0px 5px 5px 0px'}
                              width={'20%'}
                              backgroundColor={
                                timeEventTypes.items.stoppage.props.features
                                  .buttons.stop.colour
                              }
                              options={[
                                {
                                  name: 'Snap',
                                  value: 'SNAP'
                                },
                                {
                                  name: 'Kick Off',
                                  value: 'KICKOFF'
                                }
                              ]}
                              selected={''}
                              variant={'outlined'}
                              onChange={(value) =>
                                setSelectedStoppageOption(value)
                              }
                            />
                          </div>
                        ) : (
                          <Button
                            className='btn--border'
                            handleClick={() => {
                              if (!events.stoppageEventLoading) {
                                startStoppage()
                              }
                            }}
                            style={{
                              backgroundColor:
                                timeEventTypes.items.stoppage.props.features
                                  .buttons.start.colour,
                              width: '100%'
                            }}
                          >
                            {
                              timeEventTypes.items.stoppage.props.features
                                .buttons.start.text
                            }
                          </Button>
                        )}
                      </div>
                    ) : (
                      <div className={styles.startStopButton}>
                        {playStopped ? (
                          <div
                            style={{ display: 'flex', flexDirection: 'column' }}
                          >
                            <Button
                              className='btn--border'
                              handleClick={() => {
                                if (!events.stoppageEventLoading) {
                                  endStoppage()
                                }
                              }}
                              style={{
                                backgroundColor:
                                  timeEventTypes.items.stoppage.props.features
                                    .buttons.stop.colour
                              }}
                            >
                              {
                                timeEventTypes.items.stoppage.props.features
                                  .buttons.stop.text
                              }
                            </Button>
                          </div>
                        ) : (
                          <div
                            style={{ display: 'flex', flexDirection: 'column' }}
                          >
                            <Button
                              className='btn--border'
                              handleClick={() => {
                                if (!events.stoppageEventLoading) {
                                  startStoppage()
                                }
                              }}
                              style={{
                                backgroundColor:
                                  timeEventTypes.items.stoppage.props.features
                                    .buttons.start.colour
                              }}
                            >
                              {
                                timeEventTypes.items.stoppage.props.features
                                  .buttons.start.text
                              }
                            </Button>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  {sportTypes.isType('rugbyUnion', sport.value) && (
                    <div className={styles.startStopButton}>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Button
                          className='btn--border'
                          handleClick={handlePenatlyButtonClick}
                          style={{
                            backgroundColor: events.penaltyCountdownActive
                              ? sportableColors.colors.success
                              : sportableColors.colors.failure
                          }}
                        >
                          {events.penaltyCountdownActive
                            ? 'Penalty Active'
                            : 'Start Penalty'}
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              )}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              >
                {gameEventTypes.items.handOff && inPlay && (
                  <Button
                    style={{
                      backgroundColor:
                        buttonStates === gameEventTypes.items.handOff.value
                          ? sportableColors.colors.colorSuccess
                          : sportableColors.colors.sportableBlue
                    }}
                    className='btn--ghost--primary btn--small'
                    handleClick={() =>
                      handleCreateGameEvent(gameEventTypes.items.handOff.value)
                    }
                  >
                    {gameEventTypes.items.handOff.name} (s)
                  </Button>
                )}
                {gameEventTypes.items.qbRush && (
                  <Button
                    style={{
                      backgroundColor:
                        buttonStates === gameEventTypes.items.qbRush.value
                          ? sportableColors.colors.colorSuccess
                          : sportableColors.colors.sportableBlue
                    }}
                    className='btn--ghost--primary btn--small'
                    handleClick={() =>
                      handleCreateGameEvent(gameEventTypes.items.qbRush.value)
                    }
                  >
                    {gameEventTypes.items.qbRush.name} (d)
                  </Button>
                )}
                {gameEventTypes.items.tackle && inPlay && (
                  <Button
                    style={{
                      backgroundColor:
                        buttonStates === gameEventTypes.items.tackle.value
                          ? sportableColors.colors.colorSuccess
                          : sportableColors.colors.sportableBlue
                    }}
                    className='btn--ghost--primary btn--small'
                    handleClick={() =>
                      handleCreateGameEvent(gameEventTypes.items.tackle.value)
                    }
                  >
                    {gameEventTypes.items.tackle.name} (f)
                  </Button>
                )}
                {gameEventTypes.items.qbSack && inPlay && (
                  <Button
                    style={{
                      backgroundColor:
                        buttonStates === gameEventTypes.items.qbSack.value
                          ? sportableColors.colors.colorSuccess
                          : sportableColors.colors.sportableBlue
                    }}
                    className='btn--ghost--primary btn--small'
                    handleClick={() =>
                      handleCreateGameEvent(gameEventTypes.items.qbSack.value)
                    }
                  >
                    {gameEventTypes.items.qbSack.name} (g)
                  </Button>
                )}
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  gap: '5px'
                }}
              >
                {!isSportType.rugbyUnion(sport) &&
                  !isSportType.australianRules(sport) &&
                  gameEventTypes.items.penalty &&
                  inPlay && (
                    <Button
                      style={{
                        backgroundColor:
                          buttonStates === gameEventTypes.items.penalty.value
                            ? sportableColors.colors.colorSuccess
                            : sportableColors.colors.sportableBlue
                      }}
                      className='btn--ghost--primary btn--small'
                      handleClick={() =>
                        handleCreateGameEvent(
                          gameEventTypes.items.penalty.value
                        )
                      }
                    >
                      {gameEventTypes.items.penalty.name} (n)
                    </Button>
                  )}
                {gameEventTypes.items.timeOut && (
                  <Button
                    style={{
                      backgroundColor:
                        buttonStates === gameEventTypes.items.timeOut.value
                          ? sportableColors.colors.colorSuccess
                          : sportableColors.colors.sportableBlue
                    }}
                    className='btn--ghost--primary btn--small'
                    handleClick={() =>
                      handleCreateGameEvent(gameEventTypes.items.timeOut.value)
                    }
                  >
                    {gameEventTypes.items.timeOut.name}
                  </Button>
                )}
                {gameEventTypes.items.safety && (
                  <Button
                    style={{
                      backgroundColor:
                        buttonStates === gameEventTypes.items.safety.value
                          ? sportableColors.colors.colorSuccess
                          : sportableColors.colors.sportableBlue
                    }}
                    className='btn--ghost--primary btn--small'
                    handleClick={() =>
                      handleCreateGameEvent(gameEventTypes.items.safety.value)
                    }
                  >
                    {gameEventTypes.items.safety.name}
                  </Button>
                )}
                {gameEventTypes.items.qbKneel && (
                  <Button
                    style={{
                      backgroundColor:
                        buttonStates === gameEventTypes.items.qbKneel.value
                          ? sportableColors.colors.colorSuccess
                          : sportableColors.colors.sportableBlue
                    }}
                    className='btn--ghost--primary btn--small'
                    handleClick={() =>
                      handleCreateGameEvent(gameEventTypes.items.qbKneel.value)
                    }
                  >
                    {gameEventTypes.items.qbKneel.name}
                  </Button>
                )}
                {gameEventTypes.items.fumble && inPlay && (
                  <Button
                    style={{
                      backgroundColor:
                        buttonStates === gameEventTypes.items.fumble.value
                          ? sportableColors.colors.colorSuccess
                          : sportableColors.colors.sportableBlue
                    }}
                    className='btn--ghost--primary btn--small'
                    handleClick={() =>
                      handleCreateGameEvent(gameEventTypes.items.fumble.value)
                    }
                  >
                    {gameEventTypes.items.fumble.name}
                  </Button>
                )}
              </div>
              <div className={styles.stopAndSwitchSidesControlsContainer}>
                <div className={styles.possessionAndSwitchSideControls}>
                  <Button
                    className='btn--primary btn--small'
                    handleClick={handleSwitchPossession}
                    style={{ whiteSpace: 'normal' }}
                  >
                    Switch possession
                  </Button>

                  <Button
                    className='btn--ghost--primary btn--small'
                    handleClick={switchTeamSidesConfirmationModal}
                    // disabled={
                    //   (firstHalf && !firstHalf.timeEnd) ||
                    //   (secondHalf && !secondHalf.timeEnd)
                    // }
                  >
                    Swap sides
                  </Button>
                </div>
              </div>
            </div>
          </CardList>
        </div>
      )}
      <div
        style={live ? {} : { height: '100%' }}
        className={styles.tableContainer}
      >
        <CardList
          col={12}
          items={[{}]}
          scrollerId={`scroller-${1}`}
          className='maxHeight'
        >
          <div className='card-table-container'>
            {live && (
              <div className={styles.headerContainer}>
                <div className={styles.tableToggle}>
                  <Toggle
                    input={{
                      value: table,
                      onChange: (value: string) => setTable(value)
                    }}
                    options={[
                      { name: 'Coding', value: 'coding' },
                      { name: 'Controls', value: 'controls' }
                    ]}
                    size={'s'}
                  />
                </div>

                <div
                  style={{
                    opacity: autoSleepEnabled ? 0.3 : 1
                  }}
                  className={styles.tagSleepControls}
                >
                  <div className={styles.wakeAllButtonContainer}>
                    <Button
                      handleClick={() => {
                        dispatch(demoteAllOffPitch())
                      }}
                      className={`btn--thin btn--border btn--small ${styles.sleepAllButton}`}
                      type='button'
                    >
                      Demote All Off-Field
                    </Button>
                  </div>
                  <div className={styles.wakeAllButtonContainer}>
                    {sport.props.features.enablePromoteAllDevicesOnPitch && (
                      <Button
                        handleClick={() => {
                          dispatch(promoteAllOnPitch())
                        }}
                        className={`btn--thin btn--border btn--small ${styles.wakeAllButton}`}
                        type='button'
                      >
                        Promote All On-Field
                      </Button>
                    )}
                    {sport.props.features.enablePromoteAllDevices && (
                      <Button
                        handleClick={() => {
                          dispatch(promoteAll())
                        }}
                        className={`btn--thin btn--border btn--small ${styles.wakeAllButton}`}
                        type='button'
                      >
                        Promote All
                      </Button>
                    )}
                  </div>
                  <div className={styles.wakeAllButtonContainer}>
                    <Button
                      handleClick={() => {
                        dispatch(
                          setInfo({
                            header: 'Reset seat swapping',
                            message: `Are you sure you want to reset seat swapping?`,
                            proceed: () => dispatch(resetSeatSwapping())
                          })
                        )
                      }}
                      className={`btn--thin btn--border btn--small ${styles.resetSeatSwapping}`}
                      type='button'
                    >
                      Reset Seat Swapping
                    </Button>
                  </div>
                </div>
              </div>
            )}
            <div
              style={!live ? { height: '100%' } : {}}
              className={styles.eventsTableContainer}
            >
              {table === 'coding' && (
                <Table
                  // Row props
                  highlightRow={setHighlightedEvent}
                  highlightedRow={highlightedEvent}
                  id='eventTable'
                  smallHead={true}
                  // controls
                  controls={[
                    {
                      name: visibleCodingTableEvents.includes('possession')
                        ? 'Hide Possession'
                        : 'Show Possession',
                      callback: () => {
                        togglePossessionEvents()
                      }
                    },
                    {
                      name: visibleCodingTableEvents.includes('stoppage')
                        ? 'Hide Stoppages'
                        : 'Show Stoppages',
                      callback: () => toggleStoppageEvents()
                    }
                  ]}
                  disableKeyboardShortcuts={{
                    right: true,
                    left: true,
                    up: false,
                    down: false
                  }}
                  // Table props
                  options={{
                    initialOrder: live ? 'dec' : 'asc',
                    initialSortBy: 'startTime',
                    sortActive: true
                  }}
                  headerFont={13}
                  tableClass={'minimalistBlack'}
                  className={'container'}
                  data={codingTableData}
                  headers={tableHeaders}
                  pagination={true}
                  scrollShortcutsEnabled={active}
                />
              )}

              {table === 'controls' && (
                <Table
                  options={{
                    initialOrder: 'dec',
                    initialSortBy: 'id',
                    sortActive: true
                  }}
                  id='controlsTable'
                  highlightRow={(id) => {
                    dispatch(
                      setSelectedDevice(parseInt(getItemIdFromRowId(id)))
                    )
                  }}
                  highlightedRow={
                    hardware.selectedDevice
                      ? getRowId(
                          hardware.selectedDevice.toString(),
                          'controlsTable'
                        )
                      : null
                  }
                  searchBy={['serial', 'name']}
                  checkBoxes={[
                    'ball',
                    'lowPower',
                    'highPower',
                    'teamA',
                    'teamB'
                  ]}
                  headerFont={13}
                  smallHead={true}
                  tableClass={'minimalistBlack'}
                  className={'container'}
                  data={tableData}
                  headers={controlTableHeaders}
                />
              )}
            </div>
          </div>
        </CardList>
      </div>
    </React.Fragment>
  )
}
