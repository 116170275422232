import { SportscasterActionTypes } from './types'

import { setError } from '../../ui/error/actions'
import { request } from '../../utils/request_handler'
import { API_ROOT_URL } from '../../const'
import {
  getSelectedSportscaster,
  getSportscasterBaseURL,
  getSportscasterRoute
} from './functions'
import { isLocal } from '../env'
import { sportscasterConfig, sportscasterStatus } from './data'
import { getBrokers } from '../mqtt/actions'

import { MQTTActionTypes } from '../mqtt/types'

const {
  GET_SPORTSCASTER_CONFIG,
  SET_SPORTSCASTER_HEALTH,
  GET_SPORTSCASTER_FLIGHTS,
  CLEAR_SPORTSCASTER,
  ADD_SPORTSCASTER_FLIGHT,
  SET_SPORTSCASTER_EVENT,
  SET_SPORTSCASTER_STATUS,
  SET_SELECTED_SPORTSCASTER,
  SET_SELECTED_BROKER,
  SET_CONNECTION_ESTABLISHED,
  SET_LOCATION_NAME,
  SET_SPORTSCASTER_LOCATION_NAMES
} = SportscasterActionTypes

export function setSelectedSportscaster(selectedSportscasterHost) {
  return {
    type: SET_SELECTED_SPORTSCASTER,
    payload: selectedSportscasterHost
  }
}

export function setSelectedBroker(selectedBrokerHost) {
  return {
    type: SET_SELECTED_BROKER,
    payload: selectedBrokerHost
  }
}

// Get sportscaster config
export function getSportscasterConfig(fetchConfig) {
  return (dispatch, getState) => {
    const { config } = getState()

    if (!fetchConfig) {
      dispatch({
        type: GET_SPORTSCASTER_CONFIG,
        payload: {
          data: [sportscasterConfig]
        }
      })

      dispatch({
        type: SET_SPORTSCASTER_STATUS,
        payload: [sportscasterStatus]
      })

      dispatch(getBrokers(sportscasterConfig))
      return
    }

    const success = (response) => {
      // Check for bug
      response.data.forEach((x) => {
        if (x.name === 'IP ()') {
          console.error('empty config sent:', { ...x })
        }
      })
      let brokers = response.data
        .filter((x) => {
          if (x.name === 'IP ()') return false
          return true
        })
        .map((x) => {
          if (x.name === 'Local') {
            if (!x.SportscasterHost)
              x.SportscasterHost = sportscasterConfig.SportscasterHost
            if (!x.WSSHost) {
              x.WSSHost = sportscasterConfig.WSSHost
              x.protocol = sportscasterConfig.protocol
              x.path = sportscasterConfig.path
            }
          }
          return x
        })
      dispatch({
        type: GET_SPORTSCASTER_CONFIG,
        payload: {
          data: brokers
        }
      })
      dispatch({
        type: SET_SPORTSCASTER_LOCATION_NAMES,
        payload: response.data[0] ? [response.data[0].locationName] : []
      })
      dispatch({
        type: MQTTActionTypes.SET_BROKERS,
        payload: { brokers, selectedHost: response.data[0]?.WSSHost }
      })
    }
    const error = (error, errorType) => {
      dispatch(
        setError({
          message:
            error.response &&
            error.response.data &&
            error.response.data[errorType]
              ? error.response.data[errorType]
              : `Failed to get sportscaster config from metrics server. API request failed. Check log for more detail.`
        })
      )
    }
    const options = {
      url: `config/sportscasters`,
      method: 'get',
      baseURL: API_ROOT_URL,
      withCredentials: true
    }
    return request(options, success, error, dispatch)
  }
}

// Get sportscaster status
export function getSportscasterStatus() {
  return (dispatch, getState) => {
    const previousStatus = getState().sportscaster.status

    if (!isLocal && !previousStatus[0]) {
      return dispatch({
        type: SET_SPORTSCASTER_STATUS,
        payload: [sportscasterStatus]
      })
    }

    const success = (response) => {
      if (JSON.stringify(response.data) !== JSON.stringify(previousStatus)) {
        dispatch({
          type: SET_SPORTSCASTER_STATUS,
          payload: response.data
        })
      }
    }
    const error = (error, errorType) => {
      // dispatch(
      //   setError({
      //     message:
      //       error.response &&
      //       error.response.data &&
      //       error.response.data[errorType]
      //         ? error.response.data[errorType]
      //         : `Failed to get sportscaster status from metrics server. API request failed. Check log for more detail.`
      //   })
      // )
    }
    const options = {
      url: `config/sportscasters/status`,
      method: 'get',
      baseURL: API_ROOT_URL,
      withCredentials: true
    }
    return request(options, success, error, dispatch)
  }
}

// Get sportscaster version
export function getSportscasterVersions() {
  return (dispatch, getState) => {
    const sportscasters = getState().sportscaster.config

    sportscasters.forEach((sportscasterConfig) => {
      const success = (response) => {
        dispatch(
          setSportscasterHealth({
            host: sportscasterConfig.WSSHost,
            version: response.data,
            healthy: true
          })
        )
      }
      const error = (error, errorType) => {
        dispatch(
          setSportscasterHealth({
            host: sportscasterConfig.WSSHost,
            version: '',
            healthy: false
          })
        )
      }

      if (!sportscasterConfig.SportscasterHost) return

      const baseURL = getSportscasterBaseURL(sportscasterConfig)

      const options = {
        url: `api/version`,
        method: 'get',
        baseURL,
        withCredentials: true
      }
      return request(options, success, error, dispatch)
    })
  }
}

// Set sportscaster health
export function setSportscasterHealth(health) {
  return (dispatch, getState) => {
    // Only update health if it has changed
    const previousHealth = getState().sportscaster.health[health.host]
    const updatedHealth = { ...previousHealth, ...health }
    if (JSON.stringify(updatedHealth) !== JSON.stringify(previousHealth)) {
      dispatch({
        type: SET_SPORTSCASTER_HEALTH,
        payload: updatedHealth
      })
    }
  }
}

export function getLocationNames(sportscasterConfig) {
  return (dispatch) => {
    let baseURL = getSportscasterBaseURL(sportscasterConfig)
    const success = (response) => {
      let locationNames = response.data.payload
      dispatch({
        type: SET_SPORTSCASTER_LOCATION_NAMES,
        payload: locationNames
      })
    }
    const error = (error, errorType) => {
      dispatch(
        setError({
          message:
            error.response &&
            error.response.data &&
            error.response.data[errorType]
              ? error.response.data[errorType]
              : `Failed to get locations names from sportscaster. API request failed. Check log for more detail.`
        })
      )
    }
    const options = {
      url: 'api/v2/locations',
      method: 'get',
      baseURL,
      withCredentials: true
    }
    return request(options, success, error, dispatch)
  }
}

export function setLocationName(locationName) {
  return {
    type: SET_LOCATION_NAME,
    payload: locationName
  }
}

export function setConnectionEstablished(host) {
  return {
    type: SET_CONNECTION_ESTABLISHED,
    payload: host
  }
}

// Get sportscaster flights
export function getSportscasterEvents(selectedSportscaster, route) {
  return (dispatch, getState) => {
    let selectedSportscaster = getSelectedSportscaster(getState().sportscaster)
    let baseURL = getSportscasterBaseURL(selectedSportscaster)
    let sportscasterRoute = getSportscasterRoute(selectedSportscaster, route)
    const success = (response) => {
      dispatch(
        setSportscasterHealth({
          host: selectedSportscaster.SportscasterHost,
          http: {
            error: null
          }
        })
      )
      dispatch({
        type: GET_SPORTSCASTER_FLIGHTS,
        payload: { data: response.data }
      })
    }
    const error = (error, errorType) => {
      dispatch(
        setSportscasterHealth({
          host: selectedSportscaster.SportscasterHost,
          http: {
            error: 'Request failed'
          }
        })
      )
    }
    const options = {
      url: sportscasterRoute,
      method: 'get',
      baseURL,
      withCredentials: true
    }
    return request(options, success, error, dispatch)
  }
}

export function clearSportscaster() {
  return {
    type: CLEAR_SPORTSCASTER
  }
}

// Set selected flight
export function setSelectedSportscasterEvent(eventId) {
  return {
    type: SET_SPORTSCASTER_EVENT,
    payload: eventId
  }
}

// Add flight
export function addSportscasterEvent(flight) {
  return {
    type: ADD_SPORTSCASTER_FLIGHT,
    payload: flight
  }
}
