import { useEffect, useMemo, useRef, useState } from 'react'
import { useEvents, useFormattedEvent } from '../../metrics_server/events/hooks'
import { debounce } from 'lodash'
import { useAppDispatch } from '../../store/hooks'
import { updateEvent } from '../../metrics_server/events/actions'
import { Profile } from './Profile/Profile'
import { useFormattedSession } from '../../metrics_server/sessions/hooks'
import moment from '../../utils/moment'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid
} from '@mui/material'
import BasicSelect from '../Material/Select'
import { Statistic } from '../Statistic/Statistic'
import { eventTypes } from '../../metrics_server/events/data_types'
import { useBroadcasting } from '../../metrics_server/broadcasting/hooks'
import { sportableColors } from '../../constants/sportableColors'
import { manualPublish } from '../../metrics_server/broadcasting/actions'
import { useLatestKick } from '../../metrics_server/events/flight/hooks'
import Loader from '../Loader/Loader'
import * as Sentry from '@sentry/react'
import { JsonForm } from '../Forms/JsonForm/JsonForm'
import { sortArrayOfObjectsByKey } from '../../utils/helpers'
import { EventChart } from '../../metrics_server/events/components/Chart'
import { Close } from '@mui/icons-material'

export type AdjustedFontSizeType = {
  h3: string
  h5: string
  p: string
  span: string
}

export type ZoomControls = {
  zoomLevel: number
  zoomIn: () => void
  zoomOut: () => void
  adjustedFontSize: AdjustedFontSizeType
}

export const FormattedEventDetailCard = () => {
  const dispatch = useAppDispatch()

  const broadcasting = useBroadcasting()
  const { selectedEventId } = useEvents()
  const formattedEvent = useFormattedEvent(selectedEventId)
  const formattedSession = useFormattedSession(formattedEvent?.sessionId)

  const [showLineChart, setShowLineChart] = useState(false)
  const [zoomLevel, setZoomLevel] = useState(1)

  // Manual publish //

  const latestKick = useLatestKick()

  const isLastValidFlight = latestKick && formattedEvent?.id === latestKick.id
  const isPublishedFlight =
    broadcasting.publishedFlights.length >= 1 &&
    broadcasting.publishedFlights.includes(formattedEvent?.id)

  const [showValidKickDataRequestButton, setShowValidKickDataRequestButton] =
    useState(false)

  const [publishButtonLoading, setPublishButtonLoading] = useState(false)

  const handleLastValidKickClick = (flight) => {
    setPublishButtonLoading(true)
    dispatch(manualPublish(flight, () => setPublishButtonLoading(false)))
    timerStart()
    setShowValidKickDataRequestButton(!showValidKickDataRequestButton)
  }

  const countRef = useRef(null)
  const [timer, setTimer] = useState(0)
  const tenSeconds = 10000

  const timerStart = () => {
    clearInterval(countRef.current)
    countRef.current = setInterval(() => {
      setTimer((timer) => timer + 1)
    }, 1000)
  }

  useEffect(() => {
    if (latestKick) {
      if (isLastValidFlight) {
        timerStart()
      }
      // show button if latest kick is within 10 seconds of current time. timeEnd is in seconds, so we multiply by 1000 to get milliseconds
      if (tenSeconds + latestKick.timeEnd * 1000 >= Date.now()) {
        setShowValidKickDataRequestButton(true)
      } else {
        setShowValidKickDataRequestButton(false)
      }
    }

    return () => {
      clearInterval(countRef.current)
    }
  }, [timer, isLastValidFlight, latestKick])

  const renderFlightPublishFeatures = () => {
    if (publishButtonLoading) {
      return <Loader />
    }
    if (isPublishedFlight) {
      return (
        <Button
          variant='outlined'
          size='small'
          style={{
            height: '30px',
            color: sportableColors.colors.success,
            lineHeight: '1'
          }}
          disabled
        >
          Data Sent
        </Button>
      )
    }
    if (
      !broadcasting.autoBroadcastEnabled &&
      isLastValidFlight &&
      showValidKickDataRequestButton
    )
      return (
        <Button
          variant='outlined'
          size='small'
          style={{
            height: '30px',
            color: sportableColors.colors.sportableRed,
            lineHeight: '1'
          }}
          onClick={() => handleLastValidKickClick(latestKick)}
        >
          Send Data
        </Button>
      )

    return null
  }

  const handleEventHighlight = (value: boolean) => {
    const data = {
      id: formattedEvent.id,
      operatorNotes: formattedEvent.operatorNotes
        ? {
            ...formattedEvent.operatorNotes,
            highlight: value
          }
        : {
            highlight: value
          }
    }

    dispatch(updateEvent(data))
  }

  // If there is no formatted event selected, return null
  if (!formattedEvent) return null
  // If there is no formatted session for the event, return null
  if (!formattedSession) return null

  const { sport, isOfficiatingMode, isMatchMode } = formattedSession

  const handleZoomIn = () => {
    setZoomLevel((prevZoom) => prevZoom + 0.1)
  }

  const handleZoomOut = () => {
    setZoomLevel((prevProps) => (prevProps -= 0.1))
  }

  const getCappedZoomLevel = (maxZoom) => Math.min(zoomLevel, maxZoom)

  const adjustedFontSize = {
    h3: `${18 * getCappedZoomLevel(1.5)}px`,
    h5: `${14 * getCappedZoomLevel(1.5)}px`,
    p: `${12 * getCappedZoomLevel(1.5)}px`,
    span: `${12 * getCappedZoomLevel(1.5)}px`,
    selectFontSize: `${12 * getCappedZoomLevel(1.5)}px`,
    selectLabelMarginTop: `${8 * (0.7 - (getCappedZoomLevel(1.5) - 1))}px`,
    selectPadding: `${0 * getCappedZoomLevel(1.5)}px`
  }

  const uncappedAdjustedFontSize = {
    h3: `${18 * zoomLevel}px`,
    h5: `${14 * zoomLevel}px`,
    p: `${12 * zoomLevel}px`,
    span: `${12 * zoomLevel}px`
  }

  const zoomControls = {
    zoomLevel: zoomLevel,
    zoomIn: handleZoomIn,
    zoomOut: handleZoomOut,
    adjustedFontSize: adjustedFontSize,
    getCappedZoomLevel: getCappedZoomLevel
  }

  const toggleGraph = () => {
    setShowLineChart(!showLineChart)
  }

  const eventTimeSinceStart = moment
    .unix(formattedEvent?.startTime - formattedSession.startTime.unixSeconds)
    .utc()
    .format('HH:mm:ss')

  const eventStartTime = moment
    .unix(formattedEvent?.startTime)
    .utc()
    .format('HH:mm:ss')

  const metricsWithOptions = formattedEvent?.metrics
    ? Object.values(formattedEvent.metrics).filter((metric) => {
        return metric.options && !metric.readonly
      })
    : null
  const metricsWithoutOptions = formattedEvent?.metrics
    ? Object.values(formattedEvent.metrics).filter(
        (metric) => (!metric.options || metric.readonly) && !metric.group
      )
    : null
  const metricsWithoutOptionsWithGroup = formattedEvent?.metrics
    ? Object.values(formattedEvent.metrics).filter(
        (metric) => (!metric.options || metric.readonly) && metric.group
      )
    : null

  const combinedMetrics = [
    ...(metricsWithoutOptions || []),
    ...(metricsWithoutOptionsWithGroup || [])
  ]

  const sortedCombinedMetrics = sortArrayOfObjectsByKey(
    [...combinedMetrics],
    'key'
  )

  if (formattedEvent.hasFailedToRender) {
    return (
      <ErrorComp
        err={formattedEvent.hasFailedToRender}
        formattedEvent={formattedEvent}
      />
    )
  }

  try {
    return (
      <Sentry.ErrorBoundary fallback={<div>Something went wrong</div>}>
        <Grid container columnSpacing={1}>
          <Grid item xs={2.6}>
            {showLineChart && (
              <div
                style={{
                  height: '400px',
                  width: '800px',
                  overflow: 'hidden',
                  position: 'absolute',
                  bottom: '100%',
                  left: '50%',
                  transform: 'translate(-50%, 0%)',
                  zIndex: 100,
                  backgroundColor: 'white',
                  boxShadow: '0 1px 15px 1px rgba(0, 0, 0, 0.5)',
                  borderRadius: '10px',
                  padding: '20px',
                  border: '1px #e3e3e3 solid',
                  marginBottom: '30px'
                }}
              >
                <div style={{ height: '20px' }}>
                  <Close
                    onClick={toggleGraph}
                    style={{
                      position: 'absolute',
                      top: '10px',
                      right: '10px',
                      cursor: 'pointer'
                    }}
                  />
                </div>
                <div style={{ height: 'calc(100% - 20px)' }}>
                  {' '}
                  <EventChart eventId={formattedEvent.id} />
                </div>
              </div>
            )}
            {formattedEvent.team || formattedEvent.player ? (
              <Profile
                zoomControls={{
                  ...zoomControls,
                  adjustedFontSize: {
                    ...adjustedFontSize,
                    h3: `${16 * getCappedZoomLevel(1.5)}px`,
                    h5: `${12 * getCappedZoomLevel(1.5)}px`,
                    p: `${11 * getCappedZoomLevel(1.5)}px`
                  }
                }}
                team={formattedEvent.team?.selected}
                player={formattedEvent.player?.selected}
                matchTime={formattedEvent?.operatorNotes?.matchTime}
                eventTimeSinceStart={eventTimeSinceStart}
                eventStartTime={eventStartTime}
                operatorNotes={formattedEvent.operatorNotes}
                updateMatchTime={debounce((value) => {
                  dispatch(
                    updateEvent({
                      id: formattedEvent.id,
                      operatorNotes: {
                        matchTime: value
                      }
                    })
                  )
                }, 500)}
                // HERE
                updateEventHighlight={handleEventHighlight}
              />
            ) : null}
          </Grid>
          <Grid item xs={2.4}>
            {formattedEvent.type && (
              <BasicSelect
                label={'Type'}
                selected={formattedEvent.type.selected.value}
                options={formattedEvent.type.options}
                size={'small'}
                variant={'standard'}
                readonly={!formattedEvent.type.options}
                onChange={(value) =>
                  dispatch(
                    updateEvent({
                      id: formattedEvent.id,
                      type: value
                    })
                  )
                }
                fontSize={adjustedFontSize.selectFontSize}
                labelMarginTop={adjustedFontSize.selectLabelMarginTop}
                padding={adjustedFontSize.selectPadding}
              />
            )}
            {formattedEvent.subType &&
              formattedEvent.subType.options &&
              formattedEvent.subType.selected && (
                <BasicSelect
                  label={'Sub Type'}
                  options={formattedEvent.subType?.options}
                  selected={formattedEvent.subType?.selected.value}
                  onChange={(value) =>
                    dispatch(
                      updateEvent({
                        id: formattedEvent.id,
                        subType: value
                      })
                    )
                  }
                  size={'small'}
                  variant={'standard'}
                  fontSize={adjustedFontSize.selectFontSize}
                  labelMarginTop={adjustedFontSize.selectLabelMarginTop}
                  padding={adjustedFontSize.selectPadding}
                />
              )}
            {formattedEvent.outcome &&
              formattedEvent.outcome.options &&
              formattedEvent.outcome.selected && (
                <BasicSelect
                  label={'Outcome'}
                  options={formattedEvent.outcome?.options}
                  selected={formattedEvent.outcome?.selected.value}
                  onChange={(value) =>
                    dispatch(
                      updateEvent({
                        id: formattedEvent.id,
                        outcome: value
                      })
                    )
                  }
                  size={'small'}
                  variant={'standard'}
                  fontSize={adjustedFontSize.selectFontSize}
                  labelMarginTop={adjustedFontSize.selectLabelMarginTop}
                  padding={adjustedFontSize.selectPadding}
                />
              )}
            {metricsWithOptions &&
              metricsWithOptions.map((metric) => {
                return (
                  <Grid container direction='row' key={metric.key}>
                    <Grid item xs={metric.tagOnClick && !metric.value ? 8 : 12}>
                      <BasicSelect
                        key={metric.key}
                        label={metric.name}
                        options={metric.options}
                        selected={metric.value === null ? '' : metric.value}
                        onChange={(value) => {
                          const newValue = value === '' ? null : value
                          dispatch(
                            updateEvent({
                              id: formattedEvent.id,
                              [metric.key]: newValue
                            })
                          )
                        }}
                        size='small'
                        variant='standard'
                        fontSize={adjustedFontSize.selectFontSize}
                        labelMarginTop={adjustedFontSize.selectLabelMarginTop}
                        padding={adjustedFontSize.selectPadding}
                      />
                    </Grid>
                    {metric.tagOnClick && !metric.value ? (
                      <Grid item xs={2} alignSelf={'flex-end'}>
                        <Button
                          size='small'
                          onClick={() =>
                            metric.tagOnClick((key, value) => {
                              dispatch(
                                updateEvent({
                                  id: formattedEvent.id,
                                  [key]: value
                                })
                              )
                            })
                          }
                        >
                          {metric.name}
                        </Button>
                      </Grid>
                    ) : null}
                  </Grid>
                )
              })}
          </Grid>
          <Grid item xs={5.5}>
            <Grid container spacing={2.5}>
              {sortedCombinedMetrics &&
                sortedCombinedMetrics.map((metric) => {
                  if (!metric.hideOnDetailCard) {
                    return (
                      <Grid item xs={2.4} key={metric.name}>
                        <Statistic
                          title={metric.name}
                          stat={metric.display || '-'}
                          adjustedFontSize={uncappedAdjustedFontSize}
                          tag={metric.tag}
                          tagOnClick={
                            metric?.tagOnClick
                              ? () => {
                                  metric?.tagOnClick((key, value) => {
                                    dispatch(
                                      updateEvent({
                                        id: formattedEvent.id,
                                        [key]: value
                                      })
                                    )
                                  })
                                }
                              : null
                          }
                        />
                      </Grid>
                    )
                  }
                })}
            </Grid>
          </Grid>

          {eventTypes.isType('aussieRules', formattedEvent.eventType) && (
            <Grid item xs={1}>
              {formattedEvent.ballSerial && (
                <Statistic
                  title={'Ball Serial'}
                  stat={formattedEvent.ballSerial || '-'}
                  adjustedFontSize={uncappedAdjustedFontSize}
                />
              )}
              {formattedEvent.position && (
                <Statistic
                  title={'Position'}
                  stat={formattedEvent.position}
                  adjustedFontSize={uncappedAdjustedFontSize}
                />
              )}
            </Grid>
          )}

          {(isOfficiatingMode || isMatchMode) &&
            formattedEvent.eventType !== eventTypes.items.time.value &&
            formattedEvent?.features?.graph && (
              <Grid item xs={1}>
                <Button
                  variant='outlined'
                  size='small'
                  onClick={() => toggleGraph()}
                  style={{ height: '30px' }}
                >
                  Graph
                </Button>
                <Grid item xs={1} style={{ marginTop: '5px' }}>
                  {renderFlightPublishFeatures()}
                </Grid>
              </Grid>
            )}
        </Grid>
      </Sentry.ErrorBoundary>
    )
  } catch (e) {
    return <ErrorComp err={e} formattedEvent={formattedEvent} />
  }
}

interface ErrorCompProps {
  err: Error
  formattedEvent: any
}

export const ErrorComp = ({ err, formattedEvent }: ErrorCompProps) => {
  const er = `event ${formattedEvent?.id} failed to render: ${JSON.stringify(
    formattedEvent
  )}\n${err}`
  console.error(er)
  Sentry.captureException(er)
  return (
    <Sentry.ErrorBoundary>
      <Accordion style={{ overflowY: 'scroll' }}>
        <AccordionSummary>
          <h3>
            Something has gone really wrong. This event is broken and will need
            investigation.
          </h3>
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ width: '100%' }}>
            <div>${err.message}</div>
            <div>${err.stack}</div>
            <JsonForm
              stylingEnabled={false}
              disableFileInput={true}
              defaultValue={formattedEvent}
            />
          </div>
        </AccordionDetails>
      </Accordion>
    </Sentry.ErrorBoundary>
  )
}
