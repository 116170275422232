import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styles from './Table.module.scss'

//3 TanStack Libraries!!!
import { flexRender } from '@tanstack/react-table'
import { useVirtual } from 'react-virtual'
import { setSelectedFlight } from '../../metrics_server/events/actions'
import { useAppDispatch } from '../../store/hooks'
import { TableControl } from './Table.types'
import { useEvents } from '../../metrics_server/events/hooks'
import { sportableColors } from '../../constants/sportableColors'

// Benchmarking the virtual lib //
const useVirtualBenchmark = ({ parentRef, size, overscan }) => {
  // console.time('virtual')
  const rowVirtualizer = useVirtual({
    size,
    parentRef,
    estimateSize: React.useCallback(() => 35, []),
    overscan
  })
  // console.timeEnd('virtual')
  return rowVirtualizer
}

export const NewTable = ({
  table,
  controls,
  title,
  handleShiftUpShortcut,
  active
}) => {
  const [menu, setMenu] = useState({ show: false, x: 0, y: 0, rowId: null })
  const dispatch = useAppDispatch()

  const tableContainerRef = useRef<HTMLDivElement>(null)
  const events = useEvents()

  const [selectedRowIndex, setSelectedRowIndex] = useState(-1)

  const handleRowClick = (row) => {
    const event = row?.original
    dispatch(setSelectedFlight(event?.id))
  }

  const renderControl = (option: TableControl, index: number) => {
    if (option.hidden) return <noscript />
    return (
      <button
        className={`${styles.option} link button`}
        key={index}
        onClick={() => {
          const item = flatRows[selectedRowIndex]?.original
          option.callback(item)
        }}
      >
        {option.name}
      </button>
    )
  }
  const { flatRows, rows, rowsById } = table.getRowModel()

  const rowIndexMap = useMemo(() => {
    const rowIndexMap = new Map()
    flatRows.forEach((row, index) => {
      rowIndexMap.set(row.index, index)
    })
    return rowIndexMap
  }, [flatRows])

  const handleKeyDown = useCallback(
    (event) => {
      console.log('handleKeyDown')
      if (flatRows.length === 0) return
      const currentIndex =
        selectedRowIndex !== -1
          ? selectedRowIndex
          : Math.floor(flatRows.length / 2)

      let newIndex

      if (event.shiftKey) {
        if (event.key === 'ArrowUp' && handleShiftUpShortcut) {
          event.preventDefault()
          if (event.repeat) return
          const mostRecentKick = handleShiftUpShortcut()
          if (mostRecentKick) {
            const mostRecentKickId = flatRows.find(
              (row) => row?.original.id === mostRecentKick.id
            )
            setSelectedRowIndex(mostRecentKickId.index)
          }
        } else if (event.key === 'ArrowDown') {
          event.preventDefault()
          if (event.repeat) return
          //   handleShiftDownShortcut()
        }
      } else {
        switch (event.key) {
          case 'ArrowUp':
            event.preventDefault()
            if (event.repeat) return
            newIndex = Math.max(rowIndexMap.get(currentIndex) - 1, 0)
            break
          case 'ArrowDown':
            event.preventDefault()
            if (event.repeat) return
            newIndex = Math.min(
              rowIndexMap.get(currentIndex) + 1,
              rowIndexMap.size - 1
            )
            break
          default:
            return
        }

        dispatch(setSelectedFlight(flatRows[newIndex]?.original?.id))
      }
    },
    [selectedRowIndex, rowIndexMap]
  )

  useEffect(() => {
    if (active) {
      window.removeEventListener('keydown', handleKeyDown)
      window.addEventListener('keydown', handleKeyDown)
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [handleKeyDown, active])

  useEffect(() => {
    let selectedRow
    const highlightedEventId = events.selectedEventId
    if (highlightedEventId) {
      selectedRow = flatRows.find(
        (row) => row.original?.id === highlightedEventId
      )
    }
    if (selectedRow) {
      setSelectedRowIndex(selectedRow.index)
    }
  }, [events.selectedEventId])

  const rowVirtualizer = useVirtualBenchmark({
    parentRef: tableContainerRef,
    size: flatRows.length,
    overscan: 10
  })

  // useEffect(() => {
  //   if (events.selectedEventId && active) {
  //     let index = -1
  //     flatRows.forEach((row, i) => {
  //       if (row.original.id === events.selectedEventId) index = i
  //     })
  //     console.log('index', index)
  //     if (index >= 0) {
  //       rowVirtualizer.scrollToIndex(index, { align: 'center' })
  //     }
  //   }
  // }, [events.selectedEventId, active])

  const { virtualItems: virtualRows, totalSize } = rowVirtualizer

  const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0

  const paddingBottom =
    virtualRows.length > 0
      ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0)
      : 0

  const handleRightClick = (e: React.MouseEvent) => {
    if ('id' in e.target && typeof e.target.id === 'string') {
      e.preventDefault()

      const rowId = e.target.id.split('-')[0]

      setMenu({
        show: true,
        x: e.clientX,
        y: e.clientY,
        rowId: rowId
      })
    }
  }

  return (
    <>
      {(controls || title) && (
        <div className={styles.headerContainer}>
          <div className={styles.titleContainer}>
            <h5>{title}</h5>
          </div>
          <div className={styles.optionsContainer}>
            {controls.map(renderControl)}
          </div>
        </div>
      )}
      <div
        className={styles.newTableContainer}
        ref={tableContainerRef}
        style={{
          height: controls || title ? 'calc(100% - 25px)' : '100%',
          overflowY: 'scroll',
          borderBottom: '1px solid #e3e3e3'
        }}
      >
        <table className={styles['minimalistBlack']}>
          <thead style={{ position: 'sticky', top: 0 }}>
            {table.getHeaderGroups() &&
              table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <th
                        key={header.id}
                        colSpan={header.colSpan}
                        style={{
                          fontSize: '13px',
                          width: header.getSize() + '%',
                          writingMode:
                            header.id === 'ignore' ? 'vertical-rl' : undefined
                        }}
                      >
                        {header.isPlaceholder ? null : (
                          <div
                            {...{
                              className: header.column.getCanSort()
                                ? 'cursor-pointer select-none'
                                : '',
                              onClick: header.column.getToggleSortingHandler()
                            }}
                          >
                            {/* {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )} */}
                            {header.column.columnDef.header}
                            {header.column.getIsSorted() === 'asc' && (
                              <span>&#9652;</span>
                            )}
                            {header.column.getIsSorted() === 'desc' && (
                              <span>&#9662;</span>
                            )}
                          </div>
                        )}
                      </th>
                    )
                  })}
                </tr>
              ))}
          </thead>
          <tbody>
            {paddingTop > 0 && (
              <tr>
                <td style={{ height: `${paddingTop}px` }} />
              </tr>
            )}
            {virtualRows.map((virtualRow) => {
              const row = flatRows[virtualRow.index]
              const rowData = row.original

              const color =
                (rowData && rowData.id === events.selectedEventId) ||
                (rowData && rowData.hasFailedToRender)
                  ? 'white'
                  : rowData?.__color
                  ? rowData?.__color
                  : null

              return (
                <tr onContextMenu={handleRightClick} key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td
                        key={cell.id}
                        id={`${rowData.id}-${cell.id}`}
                        onClick={() => handleRowClick(row)}
                        style={{
                          backgroundColor: rowData.hasFailedToRender
                            ? 'red'
                            : rowData &&
                              rowData.id === events.selectedEventId &&
                              sportableColors.colors.tableHighlightColor,
                          fontSize: '13px',
                          color: color,
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis'
                        }}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
            {paddingBottom > 0 && (
              <tr>
                <td style={{ height: `${paddingBottom}px` }} />
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {menu.show && (
        <ContextMenu
          x={menu.x}
          y={menu.y}
          onClose={() => setMenu({ show: false, x: 0, y: 0, rowId: null })}
        >
          <CopyButton
            textToCopy={menu.rowId}
            textForButton={`Copy event ID: ${menu.rowId}`}
          ></CopyButton>
        </ContextMenu>
      )}
    </>
  )
}

const ContextMenu = ({ x, y, onClose, children }) => {
  useEffect(() => {
    const handleClick = () => onClose()
    document.addEventListener('click', handleClick)
    return () => document.removeEventListener('click', handleClick)
  }, [onClose])

  return (
    <div
      className='fixed bg-white shadow-lg border rounded-md p-2 z-50'
      style={{ left: x, top: y, zIndex: 9999 }}
    >
      {children}
    </div>
  )
}

const CopyButton = ({ textToCopy, textForButton }) => {
  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(textToCopy)
    } catch (err) {
      console.error('failed to copy', err)
    }
  }

  return (
    <button
      className='block w-full text-left px-4 py-2 hover:bg-gray-100'
      onClick={copyToClipboard}
    >
      {textForButton}
    </button>
  )
}
