import {
  UPDATE_HARDWARE,
  TOGGLE_IGNORE_TAG_FOR_SLEEP_AND_WAKE_ALL,
  HardwareState,
  SeatConfiguration
} from './types'
import * as api from './api'
import { handleAPIError, setError } from '../../ui/error/actions'
import { API_ROOT_URL } from '../../const'
import { request } from '../../utils/request_handler'
import { requestAction } from '../api/request'
import { setSuccess } from '../../ui/success/actions'
import { formatHardwareData } from './functions'
import { initialState } from './reducer'
import { UpdateSeatConfigurationRequestBody } from './api'
import { SessionModeTypeValues } from '../sessions/modes/data_types'
import { SessionTypeValues } from '../sessions/data_types'
import { SportTypeValues } from '../sports/data_types'
import { SubSessionTypeValues } from '../sessions/sub_sessions/data_types'

export function updateHardware(diagnostics: HardwareState['diagnostics']) {
  return (dispatch, getState) => {
    // Convert null status (online, sleep, offline) values to empty arrays
    for (const type in diagnostics.status.online) {
      if (!diagnostics.status.online[type]) diagnostics.status.online[type] = []
    }
    for (const type in diagnostics.status.offline) {
      if (!diagnostics.status.offline[type])
        diagnostics.status.offline[type] = []
    }
    for (const type in diagnostics.status.sleep) {
      if (!diagnostics.status.sleep[type]) diagnostics.status.sleep[type] = []
    }

    if (!diagnostics.hardwareState)
      diagnostics.hardwareState = initialState.diagnostics.hardwareState

    const pitches = getState().pitches
    const formattedHardware = formatHardwareData(
      diagnostics,
      pitches?.inUse?.anchors
    )
    dispatch({
      type: UPDATE_HARDWARE,
      payload: { diagnostics, formattedHardware }
    })
  }
}

export function toggleIgnoreTagForSleepAndWakeAll(data) {
  return {
    type: TOGGLE_IGNORE_TAG_FOR_SLEEP_AND_WAKE_ALL,
    payload: data
  }
}

export function updateQaState(data) {
  return (dispatch) => {
    const error = (error, errorType) => {
      dispatch(
        setError({
          message:
            error.response &&
            error.response.data &&
            error.response.data[errorType]
              ? error.response.data[errorType]
              : `Failed to update qa state. API request failed. Check log for more detail.`
        })
      )
    }
    const options = {
      url: `devices/${data.id}`,
      method: 'PUT',
      baseURL: API_ROOT_URL,
      withCredentials: true,
      data
    }
    return request(options, null, error, dispatch)
  }
}

export function serviceAction(tagIds, type, hideSuccessModal?) {
  return (dispatch) => {
    const success = () => {
      if (!hideSuccessModal)
        dispatch(setSuccess({ message: `${type} success` }))
    }
    return dispatch(
      requestAction(api.hardwareService, success, {
        tagIds,
        type
      })
    )
  }
}

export function enableRSSIAction() {
  return (dispatch) => {
    const success = (response) => {
      dispatch(setSuccess({ message: `RSSI enabled` }))
    }
    return dispatch(requestAction(api.enableRSSI, success))
  }
}

export function disableRSSIAction() {
  return (dispatch) => {
    const success = (response) => {
      dispatch(setSuccess({ message: `RSSI disabled` }))
    }
    return dispatch(requestAction(api.disableRSSI, success))
  }
}

export function toggleCollapsedHardware(id) {
  return (dispatch) => {
    dispatch({
      type: 'TOGGLE_COLLAPSED_HARDWARE',
      payload: id
    })
  }
}

export function toggleCollapseAllHardware(collapse) {
  return (dispatch) => {
    dispatch({
      type: 'TOGGLE_COLLAPSE_ALL_HARDWARE',
      payload: collapse
    })
  }
}

// Seat Swapping //

// Set isSeatSwappingAlgoEnabled //
export function setSeatSwappingAlgoEnabled(data: boolean) {
  return {
    type: 'SET_SEAT_SWAPPING_ALGO_ENABLED',
    payload: data
  }
}

// Get seat swapping algo state //
export function getSeatSwappingAlgoState() {
  return async (dispatch) => {
    try {
      const response = await api.getSeatSwappingAlgoState()
      dispatch(setSeatSwappingAlgoEnabled(response.data.Enabled))
    } catch (error) {
      dispatch(handleAPIError(error, 'errorDetail'))
    }
  }
}

// Update seat swapping algo state //
export function updateSeatSwappingAlgoState() {
  return async (dispatch) => {
    try {
      const response = await api.updateSeatSwappingAlgoState()
      dispatch(setSeatSwappingAlgoEnabled(response.data.Enabled))
    } catch (error) {
      dispatch(handleAPIError(error, 'errorDetail'))
    }
  }
}

// Set Seat Swapping Configuration //
export function setSeatConfiguration(data: SeatConfiguration) {
  return {
    type: 'SET_SEAT_CONFIGURATION',
    payload: data
  }
}

// Get seat configuration //
export function getSeatConfiguration() {
  return async (dispatch) => {
    try {
      const response = await api.getSeatConfiguration()
      dispatch(setSeatConfiguration(response.data))
    } catch (error) {
      dispatch(handleAPIError(error, 'errorDetail'))
    }
  }
}

// Update seat Configuration//
export function updateSeatConfiguration({
  sportType,
  sessionType,
  sessionSubType
}: UpdateSeatConfigurationRequestBody) {
  return async (dispatch) => {
    try {
      const response = await api.updateSeatConfiguration({
        sportType,
        sessionType,
        sessionSubType
      })
      dispatch(setSeatConfiguration(response.data))
    } catch (error) {
      dispatch(setSeatConfiguration(null))
    }
  }
}

// Post daemon seat configuration //
export function postSeatConfigurationToDaemon(
  sessionMode: SessionModeTypeValues
) {
  return async (dispatch) => {
    try {
      const response = await api.postSeatConfigurationToDaemon({ sessionMode })
      return response
    } catch (error) {
      dispatch(handleAPIError(error, 'errorDetail'))
    }
  }
}

// Reset seat swapping //
export function resetSeatSwapping() {
  return async (dispatch) => {
    try {
      await api.resetSeatSwapping()
    } catch (error) {
      dispatch(handleAPIError(error, 'errorDetail'))
    }
  }
}

// Demote all tags and balls off pitch //
export function demoteAllOffPitch() {
  return async (dispatch) => {
    try {
      await api.demoteAllOffPitch()
    } catch (error) {
      dispatch(handleAPIError(error, 'errorDetail'))
    }
  }
}

// Promote all tags and balls on pitch //
export function promoteAllOnPitch(successMessage = false) {
  return async (dispatch) => {
    try {
      await api.promoteAllOnPitch()

      if (successMessage) {
        const message = 'Promote all on pitch success'
        dispatch(setSuccess({ message }))
      }
    } catch (error) {
      dispatch(handleAPIError(error, 'errorDetail'))
    }
  }
}

// Promote all devices //
export function promoteAll(successMessage = false) {
  return async (dispatch) => {
    try {
      await api.promoteAll()

      if (successMessage) {
        const message = 'Promote all success'
        dispatch(setSuccess({ message }))
      }
    } catch (error) {
      dispatch(handleAPIError(error, 'errorDetail'))
    }
  }
}
