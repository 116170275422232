import React, { useEffect, useState } from 'react'
import { PlayerData } from '../../types'
import { Sport } from '../../../sports/data_types'
import { containsNumber, setLimits } from '../../../../utils/helpers'
import { FormContainer } from '../../../../components/Forms/Form/Form'
import { createPlayer, editPlayer } from '../../actions'
import { useAppDispatch } from '../../../../store/hooks'
import { Team } from '../../../teams/types'

export interface PlayerFormProps {
  handleProceed?: (arg1: any, arg2?: any) => void
  playerData?: PlayerData
  sport?: Sport
  teamId?: string
}

export const PlayerForm: React.FC<PlayerFormProps> = (props) => {
  // Redux //
  const dispatch = useAppDispatch()
  // ====== //

  const { handleProceed, playerData, sport, teamId } = props
  const [fields, setFields] = useState([
    {
      name: 'img',
      type: 'img',
      label: 'Image',
      initialValue: playerData ? playerData.img : null
    },
    {
      name: 'firstName',
      type: 'text',
      label: 'First Name',
      required: true,
      initialValue: playerData ? playerData.firstName : null
    },
    {
      name: 'lastName',
      type: 'text',
      label: 'Last Name',
      required: true,
      initialValue: playerData ? playerData.lastName : null
    },
    {
      name: 'position',
      type: 'text',
      label: 'Position',
      initialValue: playerData ? playerData.position : null
    },
    {
      name: 'number',
      type: 'text',
      textType: 'number',
      label: 'Number',
      data: null,
      required: !sport?.props?.features?.positionNumbers,
      formatOnBlur: true,
      initialValue: playerData ? playerData.number : null
    },
    {
      name: 'weight',
      type: 'text',
      textType: 'number',
      label: 'Weight (kg)',
      format: (value) => {
        return setLimits(value, 60, 200)
      },
      formatOnBlur: true,
      initialValue: playerData ? playerData.weight : null
    },
    {
      name: 'height',
      type: 'text',
      textType: 'number',
      label: 'Height (cm)',
      format: (value) => {
        return setLimits(value, 150, 250)
      },
      formatOnBlur: true,
      initialValue: playerData ? playerData.height : null
    }
  ])

  // Example of form mutator
  const updateNumber = () => {
    const newFields = fields.map((field) => {
      if (field.name === 'number') {
        field.data = '10'
      }
      return field
    })
    setFields(newFields)
  }

  useEffect(() => {
    setTimeout(() => {
      updateNumber()
    }, 5000)
  }, [])

  const validate = (values: any) => {
    const errors = {
      firstName: undefined,
      lastName: undefined
    }
    if (!values.firstName) {
      errors.firstName = 'Required'
    }
    if (containsNumber(values.firstName)) {
      errors.firstName = 'Contains a number'
    }
    if (!values.lastName) {
      errors.lastName = 'Required'
    }
    if (containsNumber(values.lastName)) {
      errors.lastName = 'Contains a number'
    }

    return errors
  }

  const handleSubmit = (values: any) => {
    const data: PlayerData = {
      teamId: teamId ? teamId : null,
      ...playerData,
      firstName: values.firstName,
      lastName: values.lastName,
      position: values.position,
      number: parseInt(values.number),
      weight: values.weight,
      height: values.height,
      img: values.img
    }
    // Update player
    dispatch(
      playerData
        ? editPlayer(data)
        : createPlayer(data, handleProceed ? handleProceed : null)
    )

    if (handleProceed) {
      handleProceed(data)
    }
  }

  return (
    <FormContainer
      fields={fields}
      validate={validate}
      onSubmit={handleSubmit}
      title={!playerData ? 'Create player' : 'Update player'}
      submitText={!playerData ? 'Create player' : 'Update player'}
      secondaryButtonText={'Cancel'}
    />
  )
}
