import { useState, useEffect } from 'react'
import { useAppSelector } from '../../store/hooks'
import {
  getSelectedSportscaster,
  getSelectedBroker,
  getSportscasterTableData,
  getSporscasterTableHeaders
} from './functions'
import {
  SelectedSportscaster,
  SportscasterState,
  SportscasterTableRow
} from './types'
import { setSelectedSportscaster, setSelectedBroker } from './actions'
import { useAppDispatch } from '../../store/hooks'
// import { ApiState } from "../index"

export function useSportscaster() {
  const sportscaster = useAppSelector((state) => state.sportscaster)
  return sportscaster
}

export function useSelectedSportscaster(): SelectedSportscaster {
  const sportscaster = useSportscaster()
  const [selectedSportscaster, setSelectedSportscaster] = useState(
    getSelectedSportscaster(sportscaster)
  )

  useEffect(() => {
    setSelectedSportscaster(getSelectedSportscaster(sportscaster))
  }, [
    sportscaster.selectedSportscasterHost,
    sportscaster.status,
    sportscaster.config
  ])

  return selectedSportscaster
}

export function useSelectedBroker(): SelectedSportscaster {
  const sportscaster = useSportscaster()
  const [selectedSportscaster, setSelectedSportscaster] = useState(
    getSelectedBroker(sportscaster)
  )

  useEffect(() => {
    setSelectedSportscaster(getSelectedBroker(sportscaster))
  }, [
    sportscaster.selectedBrokerHost,
    sportscaster.status,
    sportscaster.config
  ])

  return selectedSportscaster
}

export function useSportscasterTableProps(
  sportscaster: SportscasterState,
  type: 'selectedBrokerHost' | 'selectedSportscasterHost'
) {
  const dispatch = useAppDispatch()

  function handleSelectedCheckboxChange(
    item: SportscasterTableRow,
    value: boolean
  ) {
    if (type === 'selectedSportscasterHost') {
      dispatch(setSelectedSportscaster(item.WSSHost))
    } else {
      dispatch(setSelectedBroker(item.WSSHost))
    }
  }

  const [tableData, setTableData] = useState(
    getSportscasterTableData(sportscaster, type)
  )
  const [tableHeaders, setTableHeaders] = useState(
    getSporscasterTableHeaders(handleSelectedCheckboxChange)
  )

  useEffect(() => {
    setTableData(getSportscasterTableData(sportscaster, type))
  }, [sportscaster.status, sportscaster[type], sportscaster.health])

  return {
    tableData,
    tableHeaders
  }
}
